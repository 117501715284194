import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import ProjectsCard from '../components/Projects/ProjectsCard'

const ProjectsTemplate = (props) => {
    return (
        <Layout>
            <NavbarStaging />
            <ProjectsCard />
            <Footer />
        </Layout>
    );
}

export default ProjectsTemplate