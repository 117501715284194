const LISTINGS = [
    {
        propertyType: "Single Family",
        bath: 3,
        bed: 4,
        title: "4 Beds 3 Baths Single Family",
        listPrice: "$1,598,000",
        sqftTotal: "2,508 sqrt",
        date: "Jun 23, 2022",
        city: "Dublin",
        zipCode: "94568",
        address: "4264 Healdsburg Way",
        acurate_address: "Healdsburg Way, Dublin, 94568",
        video: "LC2yy2X6cSw",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656050649/genMid.40997724_2_1_o5fffm.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 3,
        bed: 4,
        title: "4 Beds 3 Baths Single Family",
        listPrice: "$1,598,000",
        sqftTotal: "1,953 sqrt",
        date: "May 18, 2022",
        city: "Union City",
        zipCode: "94587",
        address: "4536 Darcelle Dr",
        acurate_address: "Darcelle Dr, Union City, 94587",
        video: "CwuZb_oRfdI",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656271052/e50b77df0ed9ea242d13f5fa9b170519_cgn3pm.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 4,
        bed: 4,
        title: "4 Beds 3 Baths Single Family",
        listPrice: "$1,598,000",
        sqftTotal: "2,484 sqft",
        date: "May 18, 2022",
        city: "Fremont",
        zipCode: "94539",
        address: "2045 Castillejo Way",
        acurate_address: "Castillejo Way, Fremont, CA",
        video: "CwuZb_oRfdI",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636407/2045%20Castillejo%20Way/common_1_1_viabx5.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 4,
        bed: 2,
        title: "4 Beds 2 Baths Single Family",
        listPrice: "$1,765,000",
        sqftTotal: "1,549 sqft",
        date: "Fed 24, 2022",
        city: "Pleasanton",
        zipCode: "94566",
        address: "3564 Touriga Dr",
        acurate_address: "Touriga Dr, Pleasanton, CA",
        video: "CwuZb_oRfdI",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656654991/3564%20Touriga%20Dr/living_room_1_3_qzaqgz.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 3,
        bed: 2,
        title: "3 Beds 2 Baths Single Family",
        listPrice: "$1,650,000",
        sqftTotal: "1,206 sqft",
        date: "May 6, 2022",
        city: "San Jose",
        zipCode: "94566",
        address: "752 Menker Ave",
        acurate_address: "Menker Ave, San Jose, CA 95128",
        video: "CwuZb_oRfdI",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656675/752%20Menker%20Ave/living_room_1_2_lhzgg6.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 3,
        bed: 2,
        title: "3 Beds 2 Baths Single Family",
        listPrice: "$1,606,262",
        sqftTotal: "1,767 sqft",
        date: "Jun 8, 2022",
        city: "San Jose",
        zipCode: "95123",
        address: "6262 Mountford Dr",
        acurate_address: "Mountford Dr, San Jose, CA 95123",
        video: "CwuZb_oRfdI",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658274/6262%20Mountford%20Dr/common_1_1_rkw2r4.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 4,
        bed: 4,
        title: "4 Beds 4 Baths Single Family",
        listPrice: "$2,810,000",
        sqftTotal: "2,908 sqft",
        date: "May 6, 2022",
        city: "San Ramon",
        zipCode: "94582",
        address: "3462 Ironwood Dr",
        acurate_address: "3462 Ironwood Dr, San Ramon, CA 94582",
        video: "BG5KGQKpzWM",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660759/3462%20Ironwood%20Dr/common_1_2_anjmz3.jpg",
        ]
    },
    {
        propertyType: "Single Family",
        bath: 5,
        bed: 5,
        title: "5 Beds 5 Baths Single Family",
        listPrice: "$4,550,000",
        sqftTotal: "5,286 sqft",
        date: "Mar 29, 2022",
        city: "Pleasanton",
        zipCode: "9494588582",
        address: "1518 Honey Suckle Ct",
        acurate_address: "1518 Honey Suckle Ct, Pleasanton, Ca 94588",
        video: "2wshs2MGl7Y",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725631/1518%20Honey%20Suckle%20Ct/backyard_1_4_iqowfd.jpg",
        ]
    },
    {
        propertyType: "Townhouse",
        bath: 4,
        bed: 3.5,
        title: "4 Beds 3.5 Baths Townhouse",
        listPrice: "$1,600,000",
        sqftTotal: "1,985 sqft",
        date: "Apr 11, 2022",
        city: "San Jose",
        zipCode: "95133",
        address: "1853 Midnight Cir",
        acurate_address: "1853 Midnight Cir, San Jose, CA 95133",
        video: "2wshs2MGl7Y",
        images: [
            "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819578/1853%20Midnight%20Cir/living_room_1_5_lqzdu2.jpg",
        ]
    },
]

export default LISTINGS