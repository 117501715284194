import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

import listings from "../../utils/listings"

const ProjectsCard = () => {

    const elements = []
    for(var listing of listings) {
        elements.push(
            <div className="col-lg-4 col-sm-6 col-md-6" key={listing.address}>
                <div className="single-events-box">
                    <div className="image">
                        <Link to={"/" + listing.address} className="d-block">
                            <img src={listing.images[0]} alt="event" />
                        </Link>
                        <span className="date">{listing.date}</span>
                    </div>

                    <div className="content">
                        <h3>
                            <Link to={"/" + listing.address}>
                                {listing.title}
                            </Link>
                        </h3>
                        <span className="location">
                            <i className="bx bx-map"></i> {listing.city}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
  

    return (
        <div className="events-area pt-70 pb-70">
            <div className="container">

                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Recent Projects
                    </span>
                    <h2>Check Some Of Our Recent Work</h2>
                </div>

                <div className="row">
                    { elements }
                </div>
            </div>
        </div>
    )
}

export default ProjectsCard